@import "../assets/variables.scss";

@mixin align-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
@mixin btn-secondary {
    background: $transparent;
    border-color: $primary-color;
    color: $primary-color;
    font-weight: $font-weight-Semi-bold;
    font-family: $font-family-source;
    font-size: $font-size-16;
    transition: $transition;
    &:hover {
        background: $primary-color;
        color: $white-color;
    }
}
html {
    height: 100%;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    overflow-x: hidden;
    position: relative;
    margin: 0;
    min-height: 100%;
}
.content-wrapper.main-wrapper {
    margin-left: 145px;
}
.content-wrapper {
    -webkit-transition: -webkit-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
    -moz-transition: -moz-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
    -o-transition: -o-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
    // margin-left: 90px;
    z-index: 820;
    padding: 20px 30px;
    background: #f5f5f5;
    margin-top: 70px;
    padding-bottom: 80px !important;
    .breadcrumb {
        @include align-center;
        padding: $padding;
        margin-bottom: $margin-bottom-30;
        list-style: none;
        background-color: $transparent;
        border-radius: $border;
        .breadcrumb-item + .breadcrumb-item {
            &::before {
                content: "|";
                color: $primary-color;
            }
        }
        .breadcrumb-item {
            color: $secondary-color;
            a {
                color: $primary-color;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .breadcrumb-item.active {
            &::before {
                color: $secondary-color;
            }
        }
    }
    .top-bar {
        justify-content: space-between;
        @include align-center;
        border-bottom: 1px solid #d3d3d3;
        padding-bottom: 12px;
        h3 {
            margin: $margin;
            font-size: $font-size-24;
            font-weight: $font-weight-Semi-bold;
            font-family: $font-family-source;
            color: $secondary-color;
        }
        ul {
            margin: $margin;
            padding: $padding;
            li {
                display: inline-block;
                margin-right: 15px;
                cursor: pointer;
                &:last-child {
                    margin-right: $margin;
                }
                button.btn.btn-secondary {
                    @include btn-secondary;
                    img {
                        margin-right: $margin-bottom-10;
                    }
                    svg {
                        width: $width-20;
                        height: $height-20;
                        margin-right: 5px;
                        position: $position-rel;
                        top: -2px;
                        fill: $primary-color;
                    }
                    &:hover {
                        svg {
                            fill: $white-color;
                        }
                        img {
                            filter: brightness(100);
                        }
                    }
                }
            }
        }
    }
    .card {
        box-shadow: 0 0 10px 0 #eaeaea;
        border: 0;
        padding: 20px 30px;
        height: $full-height;
        justify-content: center;
        background: $white-color;
        .titel-sel {
            @include align-center;
            justify-content: space-between;
            margin-bottom: $margin-bottom-30;
            .card-title {
                margin: $margin;
                font-family: $font-family-source;
                font-size: $font-size-20;
                font-weight: $font-weight-bold;
                letter-spacing: 0;
                color: $secondary-color;
            }
            label {
                position: $position-rel;
                margin: $margin;
                select {
                    width: 113px;
                    height: 33px;
                    color: #777878;
                    border: 1px solid #d6d9da;
                    border-radius: 5px;
                    padding: 0 6px;
                    font-size: $font-size-14;
                    font-family: $font-family-roboto;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    &:focus {
                        outline: 0;
                    }
                }
                &:after {
                    content: " ";
                    width: 0;
                    height: 0;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid #777878;
                    position: absolute;
                    top: 14px;
                    right: 10px;
                    border-radius: $border-radius;
                }
            }
        }
        img.chart-image {
            width: $full-width;
        }
        p {
            margin: $margin;
        }
        p.entity-id {
            background: $primary-color;
            color: $white-color;
            padding: 2px 10px;
            border-radius: $border-radius;
            margin-bottom: 5px;
            font-size: $font-size-16;
            font-family: $font-family-source;
            font-weight: $font-weight-Regular;
            width: $full-width;
            max-width: 188px;
            text-align: $text-align;
        }
        p.title {
            font-family: $font-family-source;
            font-size: $font-size-20;
            font-weight: $font-weight-Semi-bold;
            color: $secondary-color;
        }
        p.simulations {
            color: #878787;
            font-size: $font-size-17;
            font-family: $font-family-source;
            text-transform: capitalize;
            font-weight: $font-weight-Regular;
            @include align-center;
            line-height: normal;
            margin-top: -6px;
            span {
                color: $primary-color;
                font-size: $font-size-34;
                font-weight: $font-weight-bold;
                line-height: normal;
                margin-right: 5px;
            }
        }
    }
    .card-entity.card {
        padding: 15px;
        border-radius: $border-radius;
        transition: $transition;
        //        &:hover {
        //            background: #f86f11;
        //            p.entity-id {
        //                background: $white-color;
        //                color: $secondary-color;
        //            }
        //            p.title {
        //                color: $white-color;
        //            }
        //            p.simulations {
        //                color: $white-color;
        //                span {
        //                    color: $white-color;
        //                }
        //            }
        //        }
    }
}

.rename-btn-active {
    background: transparent !important;
    color: #f86f11 !important;
}

.merge-btn-active {
    background: transparent !important;
    color: #1084dc !important;
}
.deletes-btn-active {
    background: transparent !important;
    color: #dc3545 !important;
}
@media only screen and (max-width: 768px) {
    .content-wrapper {
        .card {
            padding: 10px 15px 10px;
            .titel-sel {
                .card-title {
                    font-size: $font-size-16 !important;
                    margin-bottom: $margin-bottom-10;
                }
            }
            p.title {
                font-size: $font-size-18;
            }
            .content-wrapper .card {
                p.simulations {
                    span {
                        font-size: 30px;
                    }
                }
            }
        }
        .card-entity.card {
            padding: 15px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .header {
        .logo-image {
            width: 160px !important;
        }
        .navbar-custom-menu {
            .user.user-menu {
                a {
                    span {
                        font-size: 14px;
                    }
                    img {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }
    .content-wrapper {
        padding: 20px 15px;
        .card {
            padding: 20px 15px;
            .titel-sel {
                .card-title {
                    font-size: $font-size-20;
                    margin-bottom: $margin;
                }
            }
            p.title {
                font-size: $font-size-18;
            }
            p.simulations {
                span {
                    font-size: 30px;
                }
            }
        }
        .card-entity.card {
            padding: 15px;
        }
    }
}

@media only screen and (max-width: 575px) {
    body {
        .header {
            .logo {
                display: flex;
                align-items: center;
                button {
                    padding-left: 0;
                }
                .logo-image {
                    width: 130px !important;
                }
            }
            .navbar-custom-menu {
                .user.user-menu {
                    a {
                        span {
                            font-size: 0 !important;
                        }
                        img {
                            width: 40px !important;
                            height: 40px !important;
                        }
                    }
                }
            }
        }
        .content-wrapper.main-wrapper {
            margin-left: 95px;
        }
        .content-wrapper {
            padding: 20px 15px;
            margin-left: 0px;
            margin-top: 70px;
            .top-bar {
                h3 {
                    margin: 0 0 $margin-bottom-10 0;
                    font-size: $font-size-20;
                }
                ul {
                    li {
                        button.btn.btn-secondary {
                            font-size: $font-size-14;
                            margin-top: $margin-bottom-10;
                        }
                    }
                }
            }
            .card {
                padding: 20px 15px;
                .titel-sel {
                    .card-title {
                        font-size: $font-size-14;
                        margin-bottom: $margin-bottom-10;
                    }
                }
                p.entity-id {
                    font-size: $font-size-14;
                    max-width: 135px;
                }
                p.title {
                    font-size: $font-size-16;
                }
                p.simulations {
                    font-size: $font-size-14;
                    margin-top: -3px;
                    span {
                        font-size: 28px;
                    }
                }
            }
            .card-entity.card {
                padding: 15px;
            }
        }
    }
}
