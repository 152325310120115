.top-five-table {
    width: 100%;
    height: 100%;
    table-layout: fixed;

    th,
    td {
        border-right: 1px solid #bfbfbf;
        vertical-align: middle;
        text-align: center;
        font-size: 16px;
    }
    th {
        border-top: 2px solid #bfbfbf;
        border-bottom: 2px solid #bfbfbf;
        padding: initial !important;
    }
    td:last-child {
        border: none;
    }

    th:last-child {
        border-right: none;
    }

    .last-row {
        border-bottom: 2px solid #bfbfbf !important;
    }

    .grey-row {
        background-color: #ededed;
    }

    .white-row {
        background-color: white;
    }

    .event-id {
        color: #6cabea;
    }

    .td-content
    {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        position: relative;
    }

    td svg
    {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    td a{
        color: unset;
    }

    tr td {
        padding: initial !important;
    }
    .newTitle {
        font-size: 12px;
    }
}
