// Variables
$PrimaryColor: #08416e;
$Space10 : 10px;
$transparent: transparent;
$margin-bottom-30: 30px;
$margin-bottom-15: 15px;
$margin-bottom-10: 10px;
$fill-height: 100vh;
$full-height: 100%;
$full-width: 100%;
$font-family: 'Poppins', sans-serif;
$secondary-color: #000;
$primary-color: #1084dc;
$color: #007bff;
$text-color: #212529;
$par-color: #848484;
$font-weight-Regular: 400;
$font-weight-Medium: 500;
$font-weight-Semi-bold: 600;
$font-weight-bold: 700;
$white-color: #fff;
$text-align: center;
$transition: all 0.5s;
$text-transform: uppercase;
$font-family-source: 'Source Sans Pro', sans-serif;
$margin-bottom: 20px;
$border-radius: 5px;
$border-radius-100: 100%;
$font-size-20: 20px;
$font-size-18: 18px;
$font-size-16: 16px;
$font-size-14: 14px;
$font-size-24: 24px;
$font-size-34: 34px;
$font-size22: 22px;
$font-size-28: 28px;
$font-size-19: 19px;
$font-size-17: 17px;
$font-size-15: 15px;
$margin: 0;
$padding: 0;
$border: 0;
$font-family-roboto: 'Roboto', sans-serif;
$box-shadow: 0 3px 10px 0 #d4d4d4;
$width-20: 20px;
$height-20: 20px;
$position-rel: relative;
$position-absol: absolute;
$display: block;
$margin-auto: 0 auto;
$display-flex: flex;
$justify-content: space-between;
$align-items: center;
$justify-content-center: center;























