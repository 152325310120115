@import "../../assets/variables.scss";

a {
    &:hover {
        text-decoration: none;
    }
}
.site-footer.main-footer {
    width: calc(100% - 145px);
}
footer.site-footer {
    // position: fixed;
    width: 100%;
    transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
    z-index: 99;
    padding: 20px 30px;
    background: $white-color;
    border: 1px solid #e6e4e4;
    border-right: $border;
    margin-left: auto;
    margin-top: 40px;
    bottom: 0;
    right: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    p {
        margin: $margin;
        color: #3a3a3a;
        font-family: $font-family-source;
        font-weight: $font-weight-Regular;
        font-size: $font-size-16;
        text-align: left;
    }
}

@media only screen and (max-width: 575px) {
    body {
        footer.site-footer {
            width: calc(100% - 0px);
            padding: 20px 15px;
            p {
                font-size: $font-size-14;
            }
        }
        .site-footer.main-footer {
            width: calc(100% - 95px);
        }
    }
}
