@import '../../assets/variables.scss';
.dash-body{
    background-color: #f5f5f5;
}
.header {
    background: $PrimaryColor;
    padding-top: 14px;
    padding-bottom: 12px;
    position: fixed;
    top: 0;
    width: $full-width;
    z-index: 999;
    .simulations-count{
        background: #fec107;
        padding: 3px 10px;
        border-radius: 9px;
        line-height: normal;
        margin-right: 10px;
        a.nav-link{
            color: #08416e !important;
            padding: 0px !important;
            font-size: 14px !important;
            font-weight: bold !important;
        }
    }
    .sidenav {
        height: 100%;
        width: 400px;
        position: fixed;
        z-index: 1;
        top: 71px;
        right: -113%;
        background-color: #fff;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 15px;
        box-shadow: 0 3px 10px 0 #9a9696c2;
        .spinner-border{
            display: block;
            margin: 0px auto;

        }
        a {
            padding: 0px;
            text-decoration: none;
            font-size: 15px;
            color: #818181;
            display: block;
            transition: 0.3s;
            color:#007bff;
            display: inline-block;
        }
        .closebtn {
            position: absolute;
            top: -10px;
            right: 11px;
            font-size: 36px;
            margin-left: 48px;
            color:#818181
        }
        .notification-heading{
            display: inline-block;
            width: 69%;
            vertical-align: middle;
            li{
                list-style: none;
            }

        }
        .dismiss-all{
            border: 1px solid red;
            background: #fff;
            color: red;
            font-size: 15px;
            padding: 2px 7px;
        }
        ul{
            padding: 10px 15px;
        }
        ::marker {
            color: #007bff;
            background: #007bff;
            /* font-size: 25px; */
        }
        h4 {
            margin-bottom: 0px;
        }
        .notification-content{
            margin-left: 20px;
            b{
                font-weight: 600 !important;
            }
            li {
                padding: 10px 0px;
                cursor: pointer;
                font-size: 16px;
                list-style:none;
                img.notification-tick{
                    width: 21px;
                    margin: 0px 6px 0px 0px;
                    vertical-align: middle;
                }
            }
            span{
                float: right;
                // background: #808080ad;
                // color: #fff;
                // border-radius: 65%;
                // padding: 1px 8px;
                // vertical-align: middle;
                // cursor: pointer;
                // width: 25px;
                // height: 25px;
                img.notification-crossbtn {
                    width: 25px;
                }
            }
            a{
                color:#000 !important;
            }
        }


      }
	.navbar-custom-menu{
		float: right;
	}
	.toggle-btn{
		background: $transparent;
		border:none;
	}
	.logo-image{
        width: 200px;
        height: 39px;
        object-fit: cover;
        object-position: top;
	}
	.navbar-custom-menu{
        .toggle-btn {
            display: none;
        }
        .mobile-menu {
            display: none;
        }
        .nav.notify{
            display: none;
        }
        .nav {
            align-items: $align-items;
            a.nav-link {
                padding: 0 15px;
                font-size: $font-size-16;
                font-family: $font-family;
                font-weight: $font-weight-Regular;
                color: $white-color;
                display: $display;
            }
            li.dropdown.notifications-menu {
                margin-right: $Space10*2;
                a {
                    color: $white-color;
                    position: $position-rel;
                    svg {
                        width: 21px;
                        fill: $white-color;
                        height: 21px;
                    }
                    span {
                        position: $position-absol;
                        background: red;
                        color: $white-color;
                        border-radius: 100%;
                        width: 16px;
                        height: 16px;
                        font-size: 12px;
                        text-align: $text-align;
                        line-height: 16px;
                        top: -7px;
                        right: -7px
                    }
                }
            }
        }
		.user.user-menu{
            a {
                color: $white-color;
                position: $position-rel;
                display: $display-flex;
                align-items: $align-items;
                span{
                    margin-right: $Space10;
                    font-size: $font-size-16;
                    font-family: $font-family-source;
                    font-weight: $font-weight-Regular;
                }
                img{
                    width: 50px;
                    height: 50px;
                }
            }
		}
		
	}
}

.user-pr-pop{
    .dropdown-menu{
        top: 12px !important;
        transform: translate3d(-188px, 45px, 0px) !important;
        width: 240px !important;
        padding: 6px !important;
        box-shadow: 2px 0px 8px #adb5bd;
        
        .card {
            padding: 0!important;
            .user-pf-item{
                color: #696969 !important;
                font-size: 14px !important;
                line-height: 16px !important;
                margin: 0px 0px !important;
                font-weight: 400 !important;
                font-family: sans-serif !important;
                padding: 12px 14px !important;
                &:hover {
                    background: #e9ecef;
                 }
                svg{
                    margin-right: 1rem;
                    font-size: 1rem;
                    vertical-align: -17%;
                }
                
            }
            
        }
        .user-pf-pop-head{
            border-bottom: 0.8px solid #adb5bd;
            padding: 12px 10px;
            .profile-user-name-2{
                background: #1084dc;
                color: #fff;
                font-weight: 600;
                width: 45px;
                height: 45px;
                font-size: 18px;
                line-height: 47px;
                letter-spacing: -1px;
                text-transform: capitalize;
                text-align: center;
                display: inline-block;
            }
            .pl-pf-dt{
                padding: 8px;
                font-size: 18px;
                font-weight: 500;
                vertical-align: middle;
                p{
                    margin:0!important;
                    font-weight: normal;
                    font-size: 14px;
                }
                
            }
        }
        // &:after{
        //     width: 0;
        //     height: 0;
        //     border-left: 6px solid transparent;
        //     border-right: 6px solid transparent;
        //     border-bottom: 6px solid black;
        //     position: absolute;
        //     top: 0;
        //     left: 50%;
        //     content: " ";
        //     transform: translateX(-50%);
        // }
    }
}

.dropdown-menu::after {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid black;
    position: absolute;
    top: -6px;
    left: 50%;
    content: " ";
    transform: translateX(-50%);
    z-index: 999999;
}

.dropdown-menu.show {
    overflow: visible;
}
@media only screen and (min-width : 990px) and (max-width: 1190px) {
    body {
        .header {
            .navbar-custom-menu {
                .nav a.nav-link{
                    padding: 0px 8px;
                    font-size: 16px;
                }
                .nav li.dropdown.notifications-menu{
                    margin-right: 10px ;
                }
            }
            
            .simulations-count {
                padding: 1px 4px;
                margin-right: 4px;
                font-size: 15px;
            }
            .navbar-custom-menu {
                .user.user-menu {
                    a {
                        span {
                            font-size: 16px !important;
                        }
                        img {
                            width: 40px  !important;
                            height: 40px !important;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width : 990px) and (max-width: 1080px) {
    body {
        .header {
            .navbar-custom-menu {
                .user.user-menu {
                    a {
                        span {
                            font-size: 0px !important;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width : 1190px) and (max-width: 1245px) {
    .header {
        .navbar-custom-menu {
            .nav{
                a.nav-link {
                    padding: 0 9px;
                }
            } 
        }
    }
}
@media only screen and (max-width: 991px) {
    body.mobile-menu-overlay{
        &:after {
            content: " ";
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: #07070747;
            width: 100%;
        }
    }
    .header {
        .simulations-count{
            margin: 5px 15px 5px 22px;
        }
        .sidenav {
            top: 62px;
        }
        .row {
            flex-wrap: inherit;
            .navbar-custom-menu {
                .toggle-btn {
                    display: inline-block;
                    vertical-align: middle;
                }
                .nav.notify{
                    display: inline-block;
                }
                .nav-item.notification a svg {
                    width: 21px;
                    fill: #fff;
                    height: 21px;
                    display: inline-block;
                }
                .nav-item.notification a{
                    color: #fff;
                    position: relative;
                }
                .nav-item.notification a span {
                    position: absolute;
                    background: red;
                    color: #fff;
                    border-radius: 100%;
                    width: 16px;
                    height: 16px;
                    font-size: 12px;
                    text-align: center;
                    line-height: 16px;
                    top: -7px;
                    right: -7px;
                }
                .desktop-menu {
                    display: none;
                }
               
                .mobile-menu {
                    background: transparent;
                    position: fixed;
                    width: 240px;
                    height: $full-height;
                    top: 0;
                    z-index: 1000;
                    right: -100%;
                    transition: all .3s ease;
                    display: $display;
                   
                    .nsf1 {
                        background: #fff;
                        height: 100%;
                        position: relative;
                        z-index: 999;
                    }
                    .nsf {
                        background: #fff;
                        padding: 10px;
                        display: $display-flex;
                        align-items: $align-items;
                        height: 56px;
                        button {
                            background: transparent;
                            width: 45px;
                            height: 45px;
                            display: $display;
                            margin-left: auto;
                            border-radius: 100%;
                            position: $position-absol;
                            top: 0;
                            border: none;
                            right: 6px;
                            // svg {
                            //     width: $width-20;
                            //     height: $height-20;
                            //     fill: gray;
                            // }
                            img{
                                width: 21px;
                            }
                        }
                        span {
                            display: $display;
                            width: 120px;
                            height: 120px;
                            margin: $margin-auto;
                            color: $white-color;
                            font-weight: $font-weight-Semi-bold;
                            letter-spacing: 5px;
                            font-size: 45px;
                            font-family: $font-family-source;
                            text-transform: $text-transform;
                            border: 5px solid #fff;
                            border-radius: 100px;
                            display: $display-flex;
                            align-items: $align-items;
                            justify-content: $justify-content-center;
                        }
                    }
                   
                    ul {
                        display: $display;
                        a {
                            // border-bottom: 1px solid #258ecd;
                            padding: 15px 15px 15px 41px;
                            font-size: $font-size-17;
                            transition: $transition;
                            border-bottom: none;
                            color: #13568d;
                            font-weight: 500;
                            position: relative;
                            &:before{
                                content: "";
                                width: 15px;
                                height: 15px;
                                position: absolute;
                                left: 19px;
                                top: 50%;
                                background-size: cover;
                                background-position: center;
                                background-repeat: no-repeat;
                                transform: translateY(-50%);
                               
                            }
                           
                        }
                        a.home-menu{
                            &:before{
                                background-image: url('./icons/ic-home.png');
                               
                            }
                        }
                        a.howitwork-menu{
                            &:before{
                                background-image: url('./icons/ic-how-it-works.png');
                               
                            }
                        }
                        a.validtion-menu{
                            &:before{
                                background-image: url('./icons/ic-validation.png');
                               
                            }
                        }
                        a.contact-menu{
                            &:before{
                                background-image: url('./icons/ic-contact-us.png');
                               
                            }
                        }
                        a.dashboard-menu{
                            &:before{
                                background-image: url('./icons/ic-dashboard.png');
                               
                            }
                        }
                        a.buy-now{
                            &:before{
                                background-image: url('./icons/bag-fill.svg');
                            }
                        }
                        a.profile-menu{
                            &:before{
                                background-image: url('./icons/ic-profile.png');
                               
                            }
                        }
                        a.sign-menu{
                            &:before{
                                background-image: url('./icons/ic-sign-in.png');
                               
                            }
                        }
                    }
                }
                .mobile-menu.active {
                    right: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    body {
        .header{
            .logo {
                display: flex;
                align-items: center;
                button {
                    padding-left: 0;
                }
                .logo-image {
                    width: 160px !important;
                    height: 28px;
                }
            }
            .navbar-custom-menu {
                .user.user-menu {
                    a {
                        span {
                            font-size: 0 !important;
                        }
                        img {
                            width: 40px  !important;
                            height: 40px !important;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width : 576px) and (max-width: 991px) {
    .mobile-menu{
        ul{
            height: 270px;
            overflow: auto;
        }
    }
}
@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;
        a {font-size: 18px;}
        .notification-heading{
            display: inline-block;
            width: 66%;
        }
    
    }
   
}
@media only screen and (max-width: 450px) {
 
    .sidenav {
        width:100% !important;
    }
}
@media only screen and (max-width: 320px) {
    body {
        .header{
            .logo {
                .logo-image {
                    width: 128px !important;
                    height: 22px;
                }
            }
        }
    }
}